.form-work-request-create-update-containers {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-top: 1rem;
}

.form-work-request-add-samples {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-top: 1rem;
}
.wk-container-and-tests-bulk-location {
    display: flex; 
    gap: 15px; 
    align-items: center; 
    justify-content: flex-end; 
    margin-bottom: -71px;
}

.work-request-container-management {
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    padding: 20px;
}